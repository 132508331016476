<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Completed marking - Online exam</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Completed marking - Online exam
                </div>
              </div>
              <div class="breadcrumb-right" v-if="selected.length > 0">
                <div class="card-toolbar">
                  <v-btn  @click="viewAllMarkedExam()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    View Marked Exams
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Enrolment key" dense outlined clearable v-model="search.exam_key"  v-on:keyup.enter="searchOngoingOnlineExam"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchOngoingOnlineExam"
                         class="mt-1 btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="text-left">
<!--                  <th class="text-center">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select">
                      <i class="form-icon"></i>
                    </label>
                  </th>-->
                  <th class="px-3">Marked date</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Mark obtained</th>
                  <th class="px-3">Marked by</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item,index) in all_completed_online_exams" :key="index">
<!--                    <td class="text-center">
                      <label class="form-checkbox">
                        <i class="form-icon"></i>
                        <input type="checkbox" :value="item.id" v-model="selected">
                      </label>
                    </td>-->
                    <td class="px-3">
                      <span  class=" font-weight-bolder">
                        {{item.result_available_date_format}}
                      </span>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.exam_key}}
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.product_name}}
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="mb-1" v-if="item.mark_obtained">
                        {{item.mark_obtained}}/{{item.availableMarks ? item.availableMarks :'100'}}
                      </div>
                      <div class="mb-1" v-else>
                        N/A
                      </div>
                    </td>
                    <td>
                      <div class="mb-1">
                        {{item.marked_by_name}}
                      </div>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewIndividualMarkedExam(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fas fa-eye</v-icon>
                                </span>
                                <span class="navi-text">View Exam</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="all_completed_online_exams.length == 0">
                    <td colspan="6" class="text-center px-3">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="all_completed_online_exams.length > 0"
                            class="pull-right mt-7"
                            @input="getAllOngoingOfficialExams"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="all_completed_marker_overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import OnlineExamMarkerService from "@/services/online-marker/OnlineExamMarkerService";

const onlineExamMarker=new OnlineExamMarkerService();
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  data(){
    return{
      search:{
        exam_key:'',
        start_date:'',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      isLoading:false,
      all_completed_online_exams:[],
      dialog:false,
      loading:false,
      selected: [],
      selectAll: false,
      remarks:'',
      total: null,
      perPage: null,
      page: null,
      all_completed_marker_overlay:false,
      menuStartDate:false,
      menuEndDate:false,
    }
  },
  methods:{
    getAllOngoingOfficialExams(){
      this.isLoading=true;
      onlineExamResult
          .getAllCompletedOnlineExamResult(this.search, this.page)
          .then(response => {
            this.all_completed_online_exams=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    searchOngoingOnlineExam(){
      this.page=1;
      this.getAllOngoingOfficialExams();
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.all_completed_online_exams) {
          this.selected.push(this.all_completed_online_exams[i].id);
        }
      }
    },
    viewAllMarkedExam(){

    },
    viewIndividualMarkedExam(resultId){
      this.all_completed_marker_overlay=true;
      onlineExamMarker
          .viewIndividualMarkedExam(resultId)
          .then((response) => {
            let status=response.data.status;
            let message=response.data.message;
            if(status=='SUCCESS'){
              this.$snotify.success(message);
              let link=response.data.link;
              if(link){
                window.open(link, '_blank');
              }
            }
            if(status=='ERROR'){
              this.$snotify.error(message);
            }
            this.getAllOngoingOfficialExams();
          })
          .catch((err) => {

          })
          .finally(() => {
            this.all_completed_marker_overlay=false;
          });

    },
  },
  mounted() {
    this.getAllOngoingOfficialExams();
  }
}
</script>